import { JsonObject, JsonProperty } from 'json2typescript';
import { publicBranch } from '../providers/branch';
import { SearchParams } from '../searchParams';
import { BookingSlotType } from './doctorSchedule';
import { ProviderServicePrice } from '../providers/service';

export enum DoctorTitleEnum {
  Specialist = 1,
  Consultant = 2,
  Surgeon = 3,
  Professor = 4,
}

export enum NextAvailability {
  today = 1,
  tomorrow = 2,
  thisWeek = 3,
}

export enum GenderEnum {
  Male = 1,
  Female = 2,
}

@JsonObject('Branch')
export class Branch {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('doctor_id', Number, true)
  doctorId: number | null = null;

  @JsonProperty('branch_id', Number, true)
  branchId: number | null = null;

  @JsonProperty('fees', Number, true)
  fees: number | null = null;

  @JsonProperty('followup_fees', Number, true)
  followupFees: number | null = null;

  @JsonProperty('booking_type', Number, true)
  bookingType: BookingSlotType | null = null;

  @JsonProperty('next_availability', String, true)
  nextAvailability: string | null = null;

  @JsonProperty('appointment_length', Number, true)
  appointmentLength: number | null = null;

  @JsonProperty('is_active', Boolean, true)
  isActive = true;

  @JsonProperty('branch', publicBranch, true)
  branch: publicBranch | null = null;
}

@JsonObject('DoctorSpeciality')
export class DoctorSpeciality {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('icon', String, true)
  icon: string | null = null;
}

@JsonObject('Speciality')
export class Speciality extends DoctorSpeciality {
  @JsonProperty('slug', String, true)
  slug: string | null = null;

  @JsonProperty('slug_ar', String, true)
  slugAr: string | null = null;
}

@JsonObject("DoctorBranchBase")
export class DoctorBranchBase {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('doctor_id', Number, true)
  doctorId: number | null = null;

  @JsonProperty('branch_id', Number, true)
  branchId: number | null = null;

  @JsonProperty('fees', Number, true)
  fees: number | null = null;

  @JsonProperty('followup_fees', Number, true)
  followupFees: number | null = null;

  @JsonProperty('booking_type', Number, true)
  bookingType: BookingSlotType | null = null;

  @JsonProperty('next_availability', String, true)
  nextAvailability: string | null = null;

  @JsonProperty('appointment_length', Number, true)
  appointmentLength: number | null = null;

  @JsonProperty('is_active', Boolean, true)
  isActive = true;

  @JsonProperty('branch', publicBranch, true)
  branch: publicBranch | null = null;

  @JsonProperty('services_data', [ProviderServicePrice], true)
  services: ProviderServicePrice[] = [];

  @JsonProperty('booking_number', String, true)
  bookingNumber: string | null = null;

}

@JsonObject('DoctorList')
export class DoctorList {
  @JsonProperty('id', Number, true)
  id: number | null = null;

  @JsonProperty('slug', String, true)
  slug: string | null = null;

  @JsonProperty('slug_ar', String, true)
  slugAr: string | null = null;

  @JsonProperty('wait_time', Number, true)
  waitTime: number = 0;

  @JsonProperty('avg_wait_time', Number, true)
  avgWaitTime: number = 0;
  
  @JsonProperty('branch', Branch, true)
  branch: Branch | null = null;

  @JsonProperty('branches', [DoctorBranchBase], true)
  branches: DoctorBranchBase[] | null = null;

  @JsonProperty('sub_specialities', [DoctorSpeciality], true)
  subSpecialities: DoctorSpeciality[] = [];

  @JsonProperty('speciality', DoctorSpeciality, true)
  speciality: DoctorSpeciality | null = null;

  @JsonProperty('total_bookings', Number, true)
  totalBookings: number = 0;

  @JsonProperty('img', String, true)
  img: string | null = null;

  @JsonProperty('name', String, true)
  name: string | null = null;

  @JsonProperty('name_ar', String, true)
  nameAr: string | null = null;

  @JsonProperty('bio', String, true)
  bio: string | null = null;

  @JsonProperty('bio_ar', String, true)
  bioAr: string | null = null;

  @JsonProperty('title', Number, true)
  title: DoctorTitleEnum | null = null;

  @JsonProperty('title_seo', String, true)
  titleSeo: string | null = null;

  @JsonProperty('title_seo_ar', String, true)
  titleSeoAr: string | null = null;

  @JsonProperty('experience_years', Number, true)
  experienceYears: number | null = null;

  @JsonProperty('score', Number, true)
  score: number | null = null;

  @JsonProperty('grad_class', Number, true)
  gradClass: number | null = null;

  @JsonProperty('avg_rating', Number, true)
  avgRating: number | null = null;

  @JsonProperty('reviews_count', Number, true)
  reviewsCount: number | null = null;

  @JsonProperty('min_fees', Number, true)
  minFees: number = 0;

  imgAlt: string | null = null;
  imgAltAr: string | null = null;

  get specialitiesList() {
    return this.subSpecialities.map((x) => x.name).join(', ');
  }

  get specialitiesListAr() {
    return this.subSpecialities.map((x) => x.nameAr).join(', ');
  }

  get titleStr() {
    return `${this.title ? DoctorTitleEnum[this.title] : ''} - ${this.specialitiesList
      }`;
  }

  init() {
    this.imgAlt = this.name + ' | ' + this.titleSeo + ' | ' + 'MEDOC';
    this.imgAltAr = this.nameAr + ' | ' + this.titleSeoAr + ' | ' + 'MEDOC';
  }
}

@JsonObject('DoctorSearchParams')
export class DoctorSearchParams extends SearchParams {
  @JsonProperty('specialities', [Number], true)
  specialities = [];

  @JsonProperty('provider', [Number], true)
  provider = [];

  @JsonProperty('is_public', Boolean, true)
  isPublic: boolean | null = null;

  @JsonProperty('is_approved', Boolean, true)
  isApproved: boolean | null = null;

  @JsonProperty('is_featured', Boolean, true)
  isFeatured: boolean | null = null;

  @JsonProperty('doctor__gender', Number, true)
  gender: GenderEnum | null = null;

  @JsonProperty('title', [Number], true)
  titles: DoctorTitleEnum[] | null = null;

  @JsonProperty('min_fees', Number, true)
  minFees: number = 0;

  @JsonProperty('max_fees', Number, true)
  maxFees: number = 1000;

  @JsonProperty('has_free_followup', Boolean, true)
  hasFreeFollowup: boolean | null = null;

  @JsonProperty('next_availability', [Number], true)
  nextAvailability: NextAvailability[] | null = null;

  @JsonProperty('companies', [Number], true)
  companies: number[] | null = null;

  @JsonProperty('speciality_slug', String, true)
  specialitySlug: string | null = null;

  @JsonProperty('location_slug', String, true)
  locationSlug: string | null = null;

  get setFiltersCount() {
    let count = 0;
    if (this.specialities.length) count++;
    if (this.provider.length) count++;
    if (this.gender) count++;
    if (this.titles?.length) count++;
    return (
      this.specialities.length ||
      this.provider.length ||
      this.gender ||
      this.titles?.length
    );
  }
}

export enum DoctorFilterEnum {
  ALL = 1,
  ACTIVE = 2,
  INACTIVE = 4,
  FEATURED = 3,
  REQUESTS = 5,
}
