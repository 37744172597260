<div id="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-4 mb-4 mb-sm-0">
        <a [routerLink]="'/' | localize">
          <img alt="MEDOC Logo" class="logo" width="40" height="40" loading="lazy" lazyLoad="true" ngOptimizedImage
            src="/assets/images/logo-white.webp" />
        </a>
        <div class="d-flex flex-column mt-4 details">
          <small>{{ "footer.copyright" | translate }}</small>
          <small class="my-2">{{ "footer.rights_reserved" | translate }}</small>
          <small>{{ "footer.location" | translate }}</small>
        </div>
        <div class="mt-3 d-flex align-items-center">
          <a href="https://www.facebook.com/Medoc.care" aria-label="Visit MEDOC on Facebook">
            <app-icon icon="facebook" size="24px" class="preserve-color-on-hover"></app-icon>
          </a>
          <a href="https://mobile.twitter.com/medocapp" class="mx-4" aria-label="Visit MEDOC on Twitter">
            <app-icon icon="twitter" size="24px" class="preserve-color-on-hover"></app-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCpjDIWeNyKAN4fcwx32iVFA" aria-label="Visit MEDOC on YouTube">
            <app-icon icon="youtube" size="24px" class="preserve-color-on-hover"></app-icon>
          </a>
        </div>
      </div>

      <div class="col-6 col-sm-2 mb-4 mb-sm-0">
        <h6 class="white mb-3">{{ "global.company" | translate }}</h6>
        <ul>
          <li><a [routerLink]="'/policies/about' | localize">{{ "nav.about" | translate }}</a></li>
          <li><a [routerLink]="'/blog' | localize">{{ "nav.blog" | translate }}</a></li>
          <li><a [routerLink]="'/contact-us' | localize">{{ "nav.contact" | translate }}</a></li>
          <li><a [routerLink]="'policies/careers' | localize">{{ "nav.careers" | translate }}</a></li>
        </ul>
      </div>

      <div class="col-6 col-sm-2 mb-4 mb-sm-0">
        <h6 class="white mb-3">{{ "global.partners" | translate }}</h6>
        <ul>
          <li><a [routerLink]="'/solution/clinic-management-system' | localize">{{ "footer.benefits" | translate }}</a>
          </li>
          <li><a [routerLink]="'/our-partners' | localize">{{ "global.our_partners" | translate }}</a></li>
          <!-- <li><a [routerLink]="'/get-started' | localize" fragment="demo">{{ "global.become_partner" | translate }}</a></li> -->
        </ul>
      </div>

      <div class="col-6 col-sm-2 mb-4 mb-sm-0">
        <h6 class="white mb-3">{{ "global.services" | translate }}</h6>
        <ul>
          <li><a [routerLink]="'/for-patients' | localize">{{ "nav.for_patients" | translate }}</a></li>
          <li><a [routerLink]="'solution/clinic-management-system' | localize">{{ "nav.for_providers" | translate }}</a>
          </li>
          <li><a [routerLink]="'solution/business' | localize">{{ "nav.for_businesses" | translate }}</a></li>
        </ul>
      </div>

      <div class="col-6 col-sm-2 mb-4 mb-sm-0">
        <h6 class="white mb-3">{{ "footer.support" | translate }}</h6>
        <ul>
          <li><a [routerLink]="'policies/terms' | localize">{{ "footer.terms_service" | translate }}</a></li>
          <li><a [routerLink]="'policies/privacy-policy' | localize">{{ "footer.privacy_policy" | translate }}</a></li>
          <li><a [routerLink]="'policies/faqs' | localize">{{ "footer.FAQs" | translate }}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>